import styled from "@emotion/styled";

export const Toppings = styled.div`
  padding-top: 12px;
`

export const Labels = styled.p`
padding-top:15px;
`

export const LabelLeft = styled.div`
padding-right:20px;
width: 190px;
`