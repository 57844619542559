import React from "react";
import {
    Wrapper,
    Container,
    Edit,
    Delete,
    IngredientsTitle,
    Quantity,
    Span
} from "./style";
import PropTypes from 'prop-types';
import {ReactComponent as EditMealDrawer} from "assets/editmealdrawer.svg";
import {ReactComponent as DeleteBank} from "assets/delete-bank.svg";

const IngredientsList = props => {

    const listOfIngredients = props.listOfIngredients;


    return (
        <Wrapper>

            {listOfIngredients.map((ingredient, index) => {

                return <Container key={index}>
                    <div>
                        <IngredientsTitle>{ingredient.ingredientsName}</IngredientsTitle>
                        <Quantity>
                            <Span>{ingredient.quantity}</Span>{ingredient.container}
                        </Quantity>
                    </div>
                    <div>
                        <Edit disabled={props.inEditProcess} onClick={() => props.handleIngredientsEdit(ingredient)}>
                            <EditMealDrawer/>
                        </Edit>
                        <Delete onClick={() => props.handleIngredientsDelete(ingredient)}>
                            <DeleteBank/>
                        </Delete>
                    </div>
                </Container>
            })
            }
        </Wrapper>
    );
};

IngredientsList.propTypes = {
    listOfIngredients: PropTypes.array,
    handleDelete: PropTypes.func,
    handleEdit: PropTypes.func,
    key: PropTypes.number,
}

IngredientsList.defaultProps = {
    onClick: () => {
    },
}

export default IngredientsList;
