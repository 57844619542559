import styled from '@emotion/styled'

export const EditImage = styled.button`
background: transparent;
border:none;
`;

export const DeleteImage = styled.button`
margin-right: 26.8px;
background: transparent;
border:none;
`