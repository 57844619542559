import React from "react"

const MealSVG = () => {
    return (
        // <div className="svgs">
        //   <img className="svgs" src="./assets/fast-foodb.svg" />
        <svg xmlns="http://www.w3.org/2000/svg" width="19.233" height="19.233" viewBox="0 0 19.233 19.233"
             fill='#899096'>
            <g id="fast-food" transform="translate(.001)">
                <path id="Path_6809"
                      d="M24.158 159.666a1.691 1.691 0 0 1-.852-1.467 4.7 4.7 0 0 1 4.7-4.7h1.935l.091-1.5H19.063l.788 12.994a.563.563 0 0 0 .562.529h3.457a2.8 2.8 0 0 1-.565-1.69 1.693 1.693 0 0 1 1.116-1.59 2.246 2.246 0 0 1 0-2.456c-.184-.041-.286-.082-.265-.122zm0 0"
                      data-name="Path 6809" transform="translate(-18.348 -146.29)"/>
                <path id="Path_6810"
                      d="M6.2 4.583h5.635a.563.563 0 0 0 0-1.127H7.237l1.519-2.329H10.7A.563.563 0 0 0 10.7 0H8.451a.563.563 0 0 0-.472.256l-2.087 3.2H.562a.563.563 0 1 0 0 1.127H6.2zm0 0"
                      data-name="Path 6810"/>
                <path id="Path_6811"
                      d="M162 225.569a.563.563 0 0 0 .563.563h12.021a.563.563 0 0 0 .563-.563 3.573 3.573 0 0 0-3.568-3.569h-6.01a3.573 3.573 0 0 0-3.569 3.569zm0 0"
                      data-name="Path 6811" transform="translate(-155.916 -213.661)"/>
                <path id="Path_6812" d="M183.127 362a1.127 1.127 0 0 0 0 2.254h9.391a1.127 1.127 0 0 0 0-2.254zm0 0"
                      data-name="Path 6812" transform="translate(-175.164 -348.402)"/>
                <path id="Path_6813"
                      d="M174.584 452h-12.021a.563.563 0 0 0-.563.563 1.692 1.692 0 0 0 1.69 1.69h9.767a1.692 1.692 0 0 0 1.69-1.69.563.563 0 0 0-.563-.563zm0 0"
                      data-name="Path 6813" transform="translate(-155.916 -435.021)"/>
            </g>
        </svg>
        // </div>
    )
}

export default MealSVG;