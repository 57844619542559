import styled from "@emotion/styled";


export const Container = styled.div`
margin: auto;
align-items: center;
align-content: center;
justify-content: center;
display: flex;
flex-direction: column;

`
export const Image = styled.div`

`

export const Text = styled.p`

font-size: 18px;
line-height: 1.22;
text-align: left;
color: #a3b0b2;
padding-top: 68px;
`