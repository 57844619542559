import React from "react";
import {Popconfirm} from "antd";
import {Wrapper, Edit, InnerWrapper} from "./style"
import {ReactComponent as EditMeal} from "assets/edit-meal.svg";
import {ReactComponent as DeleteMeal} from "assets/trash-meal.svg";

const OnEditDelete = (props) => {

    return (<Wrapper><InnerWrapper>
        <Edit onClick={() => props.editIngredient()}>
            <EditMeal/>>
        </Edit>
        <Popconfirm
            title="Are you sure delete this Ingredient?"
            onConfirm={
                // props.deleteFoodGroup(selectedRow)
                () => props.deleteIngredient()
            }
            // onCancel={cancel}
            okText="Yes"
            cancelText="No"
        >
            <DeleteMeal/>
        </Popconfirm>

    </InnerWrapper>
    </Wrapper>)
}

export default OnEditDelete