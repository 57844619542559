import React from "react";
import MainWrapper from "./components";
import {HashRouter} from 'react-router-dom';
import {createBrowserHistory} from "history";
import "./global.css";

const Router = HashRouter;
const createHistory = createBrowserHistory;

const App = () =>
    <Router
        history={createHistory()}
    >
        <MainWrapper/>
    </Router>;

export default App;