import React from "react";
import {Popconfirm} from "antd";
import {EditImage, DeleteImage} from "./style";
import PropTypes from 'prop-types';
import {ReactComponent as EditMeal} from "assets/edit-meal.svg";
import {ReactComponent as DeleteMeal} from "assets/trash-meal.svg";

const EditDelete = (props) => {
    return (
        <div>
            {/* <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={props.handleEdit}> */}
            <EditImage onClick={props.handleEdit}>
                <EditMeal/>
            </EditImage>
            {/* </Popconfirm> */}
            <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={props.handleDelete}>
                <DeleteImage>
                    <DeleteMeal/>
                </DeleteImage>
            </Popconfirm>
        </div>
    );
};

EditDelete.propTypes = {
    handleDelete: PropTypes.func,
    handleEdit: PropTypes.func,
    title: PropTypes.string,
    okText: PropTypes.string,
    cancelText: PropTypes.string,
    onConfirm: PropTypes.func,
}

EditDelete.defaultProps = {
    title: "",
    okText: "OK",
    cancelText: "Cancel",
    onConfirm: () => {
    },
}

export default EditDelete;
