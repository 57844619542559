import React from "react";
import {Container, Image, Text} from "./style.js"
import PropTypes from "prop-types";
import EmptyBox from "assets/empty-box.jpg";

const EmptyImage = (props) => {
    return (
        <Container>
            <Image><img src={EmptyBox} alt="Empty box"/></Image>
            <Text>{props.text}</Text>
        </Container>
    )
}
EmptyImage.propTypes = {
    text: PropTypes.string
}

export default EmptyImage